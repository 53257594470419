/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/10/2024
 */

import React from "react";
import PropTypes from "prop-types";

const RssFeedLink = ({ url }) => {
  if (!url) {
    return null;
  }
  return (
    <div className={"dc-share-popup--rss-feed-container"}>
      <a
        href={url}
        className={"dc-share-popup--rss-feed-link"}
        target={"_revRSS"}
      >
        <i className={"material-icons"}>rss_feed</i>
        RSS Feed
      </a>
    </div>
  );
};

RssFeedLink.propTypes = {
  url: PropTypes.string,
};

export default RssFeedLink;
