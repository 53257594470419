/**
 * Created by piotr.pozniak@thebeaverhead.com on 09/11/2021.
 */

import React from "react";
import PropTypes from "prop-types";
import DesignTooltip from "../Builder/Input/DesignTooltip";
import CrownIcon from "../CrownIcon";
import classNames from "classnames";

const RadioInput = ({
  tooltip,
  isPremium,
  fieldName,
  label,
  checked,
  onChange,
  className = "",
  variant = "primary",
}) => {
  const _tooltip = tooltip ? <DesignTooltip help={tooltip} /> : null;

  const crown = isPremium ? (
    <CrownIcon
      className={"m-r-5 small"}
      tooltipMessage={
        "This is a Premium Feature. You will need to Upgrade to use it. Upgrade Now"
      }
    />
  ) : null;

  return (
    <div className={classNames("design-element checkbox", className)}>
      <label className={"option-label"}>
        {crown}
        {label}
        {_tooltip}
      </label>

      <div className="material-switch pull-right">
        <input
          id={fieldName}
          type="checkbox"
          checked={checked}
          onChange={onChange(fieldName)}
        />
        <label htmlFor={fieldName} className={`badge-${variant}`} />
      </div>
    </div>
  );
};

RadioInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  tooltip: PropTypes.shape({
    help: PropTypes.string,
    url: PropTypes.string,
  }),
  isPremium: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default RadioInput;
