/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as queueJobsActions from "../../actions/queueJobs";
import { deleteQueuedJobs } from "../../actions/queueJobs";

export function useQueueJobsStore() {
  const dispatch = useDispatch();
  const _queueJobs = useSelector((store) => store.queueJobs);

  const fetchQueueJobs = useCallback(
    async (page, type, perPage) =>
      await dispatch(queueJobsActions.fetchQueueJobs(page, type, perPage)),
    [dispatch]
  );

  const deleteQueuedJobs = useCallback(
    async (jobType, state) =>
      await dispatch(queueJobsActions.deleteQueuedJobs(jobType, state)),
    [dispatch]
  );

  const clearQueueCollection = useCallback(
    async () => await dispatch(queueJobsActions.clearQueueCollection()),
    [dispatch]
  );

  return {
    queueJobs: _queueJobs,
    fetchQueueJobs,
    clearQueueCollection,
    deleteQueuedJobs,
  };
}
