/**
 * Created by piotr.pozniak@thebeaverhead.com on 04/04/2024
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import useShowPopup from "../../../hooks/useShowPopup";
import SharePopup from "../../../WidgetsComponents/SharePopup";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";
import { ShareIcon } from "../../../consts";

const ShareCalendarButton = ({
  title,
  url,
  RSSUrl,
  text,
  className = "dc-calendar-share-button",
}) => {
  const { onShow, showPopup } = useShowPopup({ url, title, text });
  const widgetSettings = useContext(WidgetSettingsContext);

  const showCalendarShare = widgetSettings["calendarShowShare"] === 1;

  const popup = showPopup ? (
    <SharePopup
      url={url}
      title={title}
      text={text}
      onClose={onShow}
      RSSUrl={RSSUrl}
    />
  ) : null;

  if (!showCalendarShare) {
    return null;
  }

  return (
    <div>
      <div onClick={onShow} className={className}>
        <i className={"material-icons"}>{ShareIcon}</i> Share
      </div>
      {popup}
    </div>
  );
};

ShareCalendarButton.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default ShareCalendarButton;
