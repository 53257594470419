/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/09/2021.
 */

import { useContext } from "react";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";
import { getTimezoneName } from "../../../helpers/date";

export const localTzMatches = new Date().toString().match(/\(([A-Za-z\s].*)\)/);

const localTzFullName = localTzMatches
  ? localTzMatches.length == 2
    ? localTzMatches[1]
    : localTzMatches[0]
  : null;

const tz = getTimezoneName() || localTzFullName || "";
export default () => {
  const settingsContext = useContext(WidgetSettingsContext);

  const showTz = settingsContext["generalShowTz"];

  return showTz ? tz : "";
};
