/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/11/2019.
 */

import React, { useContext } from "react";

import PropTypes from "prop-types";
import DetailsRow from "./DetailsRow";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const Forms = (props) => {
  const widgetSettings = useContext(WidgetSettingsContext);
  const formLinkLabel = widgetSettings["formLinkLabel"];
  const formSectionTitle = widgetSettings["formSectionTitle"];
  const openLinkInNewTab =
    Number.parseInt(widgetSettings["openLinksNewTab"]) === 1;

  const addedEventsIds = [];
  const uniqueForms = [...new Set(props.forms)];
  const forms = uniqueForms.map((i, idx) => {
    if (addedEventsIds.indexOf(i["@id"]) >= 0) {
      return null;
    }
    addedEventsIds.push(i["@id"]);

    const description = props.showDescription ? (
      <div
        className={"dce-event-form-description"}
        dangerouslySetInnerHTML={{ __html: i.description }}
      />
    ) : null;

    return (
      <DetailsRow
        title={i.name || formSectionTitle}
        icon="assignment_turned_in"
        key={i.id}
      >
        <div>
          {description}
          <a
            href={i.url}
            target={openLinkInNewTab ? "_blank" : "_self"}
            className={"event-form-submit"}
          >
            {formLinkLabel}
          </a>
        </div>
      </DetailsRow>
    );
  });

  return forms;
};

Forms.propTypes = {
  forms: PropTypes.array,
  showDescription: PropTypes.bool,
};

export default Forms;
