/**
 * Created by piotr.pozniak@thebeaverhead.com on 23/10/2018.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import PageTemplate from "./../templates/PageTemplate";
import WidgetPreview from "../component/WidgetSettings/WidgetPreview";
import SetupTab from "./CalendarPage/SetupTab";
import DesignTab from "../component/WidgetSettings/DesignTab";
import InstallTab from "../component/WidgetSettings/InstallTab";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import IntegrationContext, {
  getStrategy,
} from "../../calendar/strategies/IntegrationContext";
import { store } from "../store";
import { useCalendarStore } from "../../../hooks/redux/calendar";
import {
  calendarFeaturesCheck,
  calendarSettingsValidator,
  getTheLowestPlanMeetingCalendarCriteria,
  updateJquery,
} from "../tools";
import Calendar from "../../calendar/components/Calendar";
import { useBuilderStore } from "../../../hooks/redux/builder";
import WidgetPreviewHeader from "../component/WidgetSettings/WidgetPreviewHeader";
import { useUserStore } from "../../../hooks/redux/user";
import { useCalendarsStore } from "../../../hooks/redux/calendars";
import WidgetName from "../component/WidgetSettings/WidgetName";
import { useAppStore } from "../../../hooks/redux/app";
import { useEventsStore } from "../../../hooks/redux/events";
import { useDomainsStore } from "../../../hooks/redux/domains";
import { useIntegrationsStore } from "../../../hooks/redux/integrations";
import NoEventsAlert from "./CalendarPage/NoEventsAlert";
import IntegrationIssueNotification, {
  hasIntegrationErrors,
} from "../component/IntegrationIssueNotification";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import useSetupChangesTrackerHook from "../hooks/useSetupChangesTrackerHook";

window.__DCE = window.__DCE || {};
window.__DCE.calendar = window.__DCE.calendar || {};

const PageTabs = [
  {
    label: "Setup",
    id: "setup_tab",
    additionalClassName: "active show",
  },
  {
    label: "Design",
    id: "design_tab",
    additionalClassName: "",
  },
  {
    label: "Install",
    id: "install_tab",
    additionalClassName: "",
  },
];

const CalendarPage = (props) => {
  const uuid = props.match.params.uuid;

  const { app } = useAppStore();
  const { user } = useUserStore();
  const { calendar } = useCalendarStore();
  const { calendars } = useCalendarsStore();
  const { clearErrors } = useAppStore();
  const { events } = useEventsStore();
  const { domains } = useDomainsStore();
  const { integrations } = useIntegrationsStore();
  const { currentOrganization } = useCurrentOrganizationStore();

  const { deleteCalendar, fetchCalendar, setCalendar, updateCalendar } =
    useCalendarStore();

  const { overwriteDesignProp, overwriteFilterProp } = useBuilderStore();

  const history = useHistory();
  const [state, setState] = useState({
    calendarModel: null,
    errors: {},
  });

  const { trackChanges, setOriginValues, hasChanges } =
    useSetupChangesTrackerHook();

  const [savePointEventsSettings, setSavePointEventsSettings] = useState(null);

  const isWidgetInitialized = useMemo(() => {
    return calendar.model &&
      calendar.model.slug &&
      integrations.fetchSuccess &&
      calendar.model.integration
      ? true
      : false;
  }, [integrations.fetchSuccess, calendar.model]);

  const integration = isWidgetInitialized
    ? integrations.collection.find(
        (integration) => integration.uuid === calendar.model.integration.uuid
      )
    : null;

  const strategy = useMemo(
    () =>
      getStrategy(
        isWidgetInitialized ? calendar.model.integration.type : null,
        calendar.model
      ),
    [isWidgetInitialized, calendar.model]
  );

  const lowestPlanRecommended = useMemo(() => {
    return state.calendarModel && state.calendarModel.integration
      ? getTheLowestPlanMeetingCalendarCriteria(state.calendarModel, calendars)
      : null;
  }, [state.calendarModel, calendars]);

  useEffect(() => {
    (async () => {
      await fetchCalendar(props.match.params.uuid);
    })();

    return () => {
      setCalendar(null);
    };
  }, []);

  useEffect(() => {
    if (calendar.model && calendar.model.integration) {
      setState({
        ...state,
        calendarModel: {
          ...calendar.model,
          integration_uuid: calendar.model.integration.uuid,
        },
      });
      setOriginValues({ ...calendar.model });
    }
  }, [calendar.model]);

  useEffect(() => {
    if (calendar.fetchSuccess) {
      if (calendar.model && !calendar.model.integration) {
        //history.push("/dashboard");
      }
    }

    if (calendars.fetchSuccess) {
      setTimeout(updateJquery, 200);
    }
  }, [calendar.fetchSuccess, events.fetchSuccess]);

  useEffect(() => {
    if (calendar.updateSuccess) {
      setSavePointEventsSettings(
        Object.assign({}, calendar.model.widget_settings)
      );

      setTimeout(clearErrors, 4500);
      setOriginValues(calendar.model);
    }
  }, [calendar.updateSuccess]);

  /**
   * track changes if design settings were updated
   */
  useEffect(() => {
    if (isWidgetInitialized && app.widgetSettingsUpdateTimestamp) {
      trackChanges("widget_settings", calendar.model.widget_settings);
    }
  }, [app.widgetSettingsUpdateTimestamp, isWidgetInitialized]);

  useEffect(() => {
    if (isWidgetInitialized) {
      setSavePointEventsSettings(
        Object.assign({}, calendar.model.widget_settings)
      );
    }
  }, [isWidgetInitialized]);

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateField = (fieldName, value) => {
    const updatedModel = {
      ...state.calendarModel,
    };
    trackChanges(fieldName, value);
    updatedModel[fieldName] = value;

    setState({
      calendarModel: updatedModel,
      errors: calendarSettingsValidator(updatedModel),
    });
  };

  /**
   * @param {string} fieldName
   * @param {string|array|object} value
   * @type {(function(*, *))|*}
   */
  const onUpdateFilterSettings = useCallback(
    (field, value) => {
      const updatedModel = {
        ...state.calendarModel,
      };
      updatedModel.filter_settings = {
        ...(updatedModel.filter_settings || {}),
      };

      updatedModel.filter_settings[field] = value;
      trackChanges("filter_settings", updatedModel.filter_settings);

      setState({
        calendarModel: updatedModel,
        errors: calendarSettingsValidator(updatedModel),
      });
    },
    [state.calendarModel]
  );

  /**
   * @param {string} fieldName
   * @param {string|array|object} value
   * @type {(function(*, *))|*}
   */
  const onUpdateWidgetSettings = useCallback(
    (field, value) => {
      const updatedModel = {
        ...state.calendarModel,
      };
      updatedModel.widget_settings = {
        ...(updatedModel.widget_settings || {}),
      };

      trackChanges(field, value);

      updatedModel.widget_settings[field] = value;

      setState({
        calendarModel: updatedModel,
        errors: calendarSettingsValidator(updatedModel),
      });
    },
    [state.calendarModel]
  );

  /**
   *
   * @param fieldName
   * @param value
   */
  const onUpdateStatus = useCallback(
    (value) => {
      const updatedModel = {
        ...state.calendarModel,
      };

      updatedModel.available = value;

      const isConfigValid = validateSettings(updatedModel);
      const hasValidSettings = calendarSettingsValidator(updatedModel);

      setState({ ...state, errors: hasValidSettings });

      if (!isConfigValid || Object.keys(hasValidSettings).length) {
        return false;
      }

      setState({
        ...state,
        calendarModel: updatedModel,
      });

      _onSettingsSubmit(updatedModel);
    },
    [state.calendarModel]
  );

  /**
   *
   */
  const onSettingsSubmit = async () => {
    const data = { ...state.calendarModel };
    const hasValidSettings = calendarSettingsValidator(data);

    setState({ ...state, errors: hasValidSettings });

    if (Object.keys(hasValidSettings).length) {
      return false;
    }
    _onSettingsSubmit(data);
  };

  /**
   *
   */
  const _onSettingsSubmit = async (data) => {
    // check if integration has changed, if so, remove calendar filters;
    if (calendar.model.integration.uuid !== data.integration_uuid) {
      data.filter_settings = null;
    }

    // it overwrites the builder settings that are updated with redux but widget_settings contain other
    // settings that are not updated with redux
    const widgetSettings = {
      ...data.widget_settings,
    };

    data.widget_settings = {
      ...calendar.model.widget_settings,
    };

    // restore data.widget_settings using widgetSettings and omit 'design' and 'highlights' keys
    Object.keys(widgetSettings).forEach((key) => {
      if (key !== "design" && key !== "highlights") {
        data.widget_settings[key] = widgetSettings[key];
      }
    });

    delete data.builder;
    const isConfigValid = validateSettings(data);

    if (!isConfigValid) {
      return;
    }

    await updateCalendar(uuid, data);
  };

  /**
   *
   * @param data
   * @returns {boolean}
   */
  const validateSettings = (data) => {
    const issues = calendarFeaturesCheck(data, currentOrganization, calendars);

    if (issues.length) {
      setState({
        ...state,
        errors: issues,
      });
      return false;
    }

    return true;
  };

  /**
   *
   */
  const onDeleteClick = async (e) => {
    e.preventDefault();

    if (calendar.fetch || calendar.delete) {
      return false;
    }

    await deleteCalendar(uuid);

    if (store.getState().calendar.deleteSuccess) {
      history.push("/calendars");
    }

    return false;
  };

  /**
   *
   */
  const onSettingsRestore = () => {
    if (
      window.confirm(
        "This will remove all the changes you have done since the last SAVE. Are you sure?"
      )
    ) {
      overwriteDesignProp(savePointEventsSettings.design);
    }
  };

  const loading =
    calendar.update ||
    calendar.fetch ||
    calendar.delete ||
    !calendar.model ||
    !calendar.model.template ||
    !isWidgetInitialized;

  const settingsTabsOptions = isWidgetInitialized ? PageTabs : [];

  const settingsTabs = settingsTabsOptions.map((item, idx) => {
    return (
      <li key={"settings-tab-" + idx} className="nav-item">
        <a
          className={"nav-link " + item.additionalClassName}
          href={"#" + item.id}
          data-toggle="tab"
        >
          {item.label}
        </a>
      </li>
    );
  });

  const designTab = isWidgetInitialized ? (
    <DesignTab
      disabled={loading}
      onSubmit={onSettingsSubmit}
      onRestore={onSettingsRestore}
      widget={calendar}
      settingsName={"design"}
      hasSetupChanged={hasChanges}
    />
  ) : null;

  const setupTab = isWidgetInitialized ? (
    <SetupTab
      integrations={integrations.collection}
      disabled={loading}
      onSubmit={onSettingsSubmit}
      isNew={false}
      overwriteDesignProp={overwriteDesignProp}
      overwriteFilterProp={overwriteFilterProp}
      user={user}
      calendarModel={state.calendarModel || {}}
      errors={state.errors}
      onUpdateField={onUpdateField}
      onUpdateFilterSettings={onUpdateFilterSettings}
      onUpdateWidgetSettings={onUpdateWidgetSettings}
      hasSetupChanged={hasChanges}
    />
  ) : null;

  const installTab = isWidgetInitialized ? (
    <InstallTab
      widget={calendar}
      disabled={loading}
      onSubmit={onSettingsSubmit}
      onUpdateStatus={onUpdateStatus}
      isNew={false}
      hasDomains={domains.collection.length ? true : false}
      widgetModel={state.calendarModel || {}}
      errors={state.errors}
      onUpdateField={onUpdateField}
      widgetTypeName={"Calendar"}
      shareableUrl={`${window.location.origin}/c/${calendar.model.slug}`}
      recommendedPlan={lowestPlanRecommended}
      onUpdateWidgetSettings={onUpdateWidgetSettings}
    />
  ) : null;

  const calendarSidebar = (
    <div
      className="col-lg-3 message-view chat-profile-view chat-sidebar"
      id="chat_sidebar"
    >
      <div className="chat-window video-window">
        <div className="fixed-header">
          <ul className="nav nav-tabs nav-tabs-bottom">{settingsTabs}</ul>
        </div>

        <div className="tab-content chat-contents">
          {setupTab}

          {designTab}

          {installTab}
        </div>
      </div>
    </div>
  );

  const calendarName = isWidgetInitialized ? calendar.model.name : "Loading...";

  const integrationIssuesInfo = isWidgetInitialized ? (
    <IntegrationIssueNotification
      integration_uuid={calendar.model.integration.uuid}
    />
  ) : null;

  /*  console.log(
    integrationIssuesInfo,
    isWidgetInitialized,
    events.fetchSuccess,
    events.collection.length
  );*/
  const noEventsToDisplayInfo =
    !hasIntegrationErrors(integration) &&
    isWidgetInitialized &&
    events.fetchSuccess &&
    !events.collection.length ? (
      <NoEventsAlert calendar={calendar} />
    ) : null;

  const calendarPreview = isWidgetInitialized ? (
    <React.Fragment>
      <WidgetPreview
        key={calendar.model.integration.type || new Date()}
        uuid={uuid}
        widget={calendar}
        widgetKind={"calendars"}
      >
        <Calendar calendarUUID={uuid} embedded={false} />
      </WidgetPreview>
      {integrationIssuesInfo}
      {noEventsToDisplayInfo}
    </React.Fragment>
  ) : (
    <LoadingIndicator />
  );

  return (
    <PageTemplate
      title={
        isWidgetInitialized ? (
          <WidgetName
            widgetName={calendarName}
            widget={calendar.model}
            isLoading={loading ? true : false}
            widgetFeaturesCheck={calendarFeaturesCheck}
          />
        ) : (
          calendarName
        )
      }
      sidebarId="calendar"
      icon={"event"}
      className={"page-wrapper-widget"}
      headerControls={[
        <WidgetPreviewHeader key={"cphc"} onDelete={onDeleteClick} />,
      ]}
    >
      <IntegrationContext.Provider value={strategy}>
        <div>
          <div className="chat-main-row">
            <div className="chat-main-wrapper">
              <div className="col-lg-9 message-view task-view">
                <div className="chat-window">{calendarPreview}</div>
              </div>

              {calendarSidebar}
            </div>
          </div>
        </div>

        <div className="sidebar-overlay" data-reff="#chat_sidebar" />
      </IntegrationContext.Provider>
    </PageTemplate>
  );
};

export default CalendarPage;
