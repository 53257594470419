/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/05/2023
 */

import React, { useContext, useEffect, useMemo } from "react";
import FilterSelect from "../component/WidgetSettings/AsyncSelectionNew";
import { useFiltersStore } from "../../../hooks/redux/filters";
import CrownIcon from "../component/CrownIcon";
import { useCurrentOrganizationStore } from "../../../hooks/redux/currentOrganization";
import { hasSubscription } from "../helpers/organization";
const BasicOperators = [
  {
    value: "is",
    label: "is",
  },
  {
    value: "not",
    label: "is not",
  },
];

/**
 * A callback function passed to filters to check whether the filter is allowed
 * @param filter
 * @param plan
 * @returns {(function(*))|*}
 */
const checkIsAllowed = (filter, plan) => (rule) => {
  return (
    filter.allowedPlans.includes(plan.code) ||
    (filter.allowedValues && filter.allowedValues.includes(rule.value))
  );
};

const renderFieldLabel = (label, filter, plan) => (rule) => {
  const isAllowed = checkIsAllowed(filter, plan)(rule);

  if (isAllowed) {
    return label;
  }

  return (
    <span className={"filter-group__rule-label__with-crown"}>
      <CrownIcon
        tooltipMessage={
          "This is a Premium Feature. You will need to Upgrade to use it. Upgrade Now."
        }
      />
      {label}
    </span>
  );
};

const useFiltersHook = ({ integrationStrategy, widget }) => {
  const { filters } = useFiltersStore();
  const { currentOrganization } = useCurrentOrganizationStore();
  const plan = hasSubscription(currentOrganization);

  // TODO this mutates itegrationStrategy._filters
  useEffect(() => {
    integrationStrategy.filterRawDataToStateObject(
      filters.integrationFiltersCollection
    );
  }, [filters.integrationFiltersCollection]);

  const groupedFilters = useMemo(() => {
    const _groupedFilters = [];
    integrationStrategy._filters.forEach((filter) => {
      _groupedFilters[filter.field.value] = [];
    });

    filters.integrationFiltersCollection.map((filter) => {
      if (!_groupedFilters[filter.kind]) {
        return;
      }
      _groupedFilters[filter.kind].push(filter);
    });

    return _groupedFilters;
  }, [filters.integrationFiltersCollection, integrationStrategy._filters]);

  const availableFilters = useMemo(
    () =>
      integrationStrategy._filters.map((filter) => {
        const _operators = filter.operators || BasicOperators;
        const operators = _operators
          .map((operator) => {
            if (filter.allowedOperators) {
              // console.log("filter.allowedOperators", filter.allowedOperators);
              if (!filter.allowedOperators.includes(operator.value)) {
                return null;
              }
            }

            return {
              ...operator,
              input: {
                component: FilterSelect,
                props: {
                  collection:
                    filter.options || groupedFilters[filter.field.value],
                  widget,
                  isMulti: false,
                  rawDataToDisplayObject:
                    integrationStrategy.filterRawToDisplayObject,
                  placeholder: `Select ${filter.field.label} to add to filter`,
                  noOptionsMessage: `Start typing to search ${filter.field.label}`,
                },
              },
            };
          })
          .filter((operator) => operator);

        return {
          field: filter.field,
          fieldComponent: renderFieldLabel(filter.field.label, filter, plan),
          isAllowed: filter.allowedValues
            ? checkIsAllowed(filter, plan)
            : filter.allowedPlans.includes(plan.code),
          operators,
        };
      }),
    [groupedFilters]
  );

  return { availableFilters };
};

export default useFiltersHook;
