/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/04/2022.
 */

import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import PreloadImage from "../PreloadImage";
import Address from "./Address";
import MeetingDayTime from "./MeetingDayTime";
import ButtonCTA from "./ButtonCTA";
import Primitives from "../primitives";
import PrimitiveBadges from "../PrimitiveBadges/PrimitiveBadges";
import Badge from "../PrimitiveBadges/Badge";
import { formatCTAUrl } from "../../tools";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const CardGroup = (props) => {
  const { group } = props;
  const widgetSettingsContext = useContext(WidgetSettingsContext);

  const showImage = Number.parseInt(widgetSettingsContext["gSImageMain"]) === 1;
  const showAddress =
    Number.parseInt(widgetSettingsContext["gcgShowAddress"]) === 1;
  const showName = Number.parseInt(widgetSettingsContext["gcgShowName"]) === 1;
  const showMeeting =
    Number.parseInt(widgetSettingsContext["gcgShowMeetingTime"]) === 1;
  const showDescription =
    Number.parseInt(widgetSettingsContext["gSDescriptionCard"]) === 1;

  const showPrimaryCta =
    Number.parseInt(widgetSettingsContext["gcCShowCta1st"]) === 1;
  const primaryCtaAction = widgetSettingsContext["gcCCTAAction1st"];
  const primaryCtaLink = group.ctas[primaryCtaAction] || null;
  const isPrimaryCTADefaultLabel =
    Number.parseInt(widgetSettingsContext["gcC1stDefaultCTALabel"]) === 1;
  const primaryCTACustomLabel = widgetSettingsContext["gcC1stCTALabel"];
  const primaryCTASubject = widgetSettingsContext["gcCCTASubject1st"];
  const primaryCTAMessage = widgetSettingsContext["gcCCTAMessage1st"];

  const showSecondaryCta =
    Number.parseInt(widgetSettingsContext["gcCShowCta2nd"]) === 1;
  const secondaryCtaAction = widgetSettingsContext["gcCCTAAction2nd"];
  const secondaryCtaLink = group.ctas[secondaryCtaAction] || null;
  const isSecondaryCTADefaultLabel =
    Number.parseInt(widgetSettingsContext["gcC2ndDefaultCTALabel"]) === 1;
  const secondaryCTACustomLabel = widgetSettingsContext["gcC2ndCTALabel"];

  const secondaryCTASubject = widgetSettingsContext["gcCCTASubject2nd"];
  const secondaryCTAMessage = widgetSettingsContext["gcCCTAMessage2nd"];

  const showPopup = Number.parseInt(widgetSettingsContext["gPopupShow"]) === 1;
  const showPrimaryBadge = widgetSettingsContext["gBCShowPrimary"];
  const showSecondaryBadge = widgetSettingsContext["gBCShowSecondary"];

  const primaryBadgeField = widgetSettingsContext["gBCShowPrimaryField"];
  const secondaryBadgeField = widgetSettingsContext["gBCShowSecondaryField"];
  const openLinkInNewTab =
    Number.parseInt(widgetSettingsContext["openLinksNewTab"]) === 1;

  const primitives = Primitives.filter(
    (i) =>
      Number.parseInt(
        widgetSettingsContext[`gcgShow${i.visibilitySetting}`]
      ) === 1
  ).map((i, idx) => {
    const value = group.primitives[i.visibilitySetting];

    // if the value is undefined or null then return null
    if (!value) {
      return null;
    }
    return (
      <div
        key={`gcd-${group.id}-${idx}`}
        className={"dce--groups-card-details-item"}
      >
        <div className={"dce--groups-card-details-header"}>{i.label}</div>
        <div className={"dce--groups-card-details-value"}>
          {group.primitives[i.visibilitySetting]}
        </div>
      </div>
    );
  });

  const hasCardContent = useMemo(() => {
    const options = [
      showAddress,
      showName,
      showMeeting,
      showDescription,
      showPrimaryCta,
      showSecondaryCta,
      showPrimaryBadge,
      showSecondaryBadge,
    ];

    return options.some((option) => option) || primitives.length > 0;
  }, [widgetSettingsContext, primitives]);

  /**
   *
   * @param e
   */
  const onCardClick = (e) => {
    e.preventDefault();
    if (props.onSelectedEvent) {
      props.onSelectedEvent(props.group);
    }
  };

  const banner =
    showImage && group.cover_image ? (
      <PreloadImage src={group.cover_image} />
    ) : null;

  const descriptionElement =
    showDescription && group.description.trim().length ? (
      <div className="line-clamp">
        <p dangerouslySetInnerHTML={{ __html: group.description }}></p>
      </div>
    ) : null;

  const address =
    showAddress && group.address ? (
      <Address address={group.address} groupSlug={group.slug} />
    ) : null;

  const meeting =
    showMeeting && group.meeting ? (
      <MeetingDayTime
        meetingTime={group.meeting.meeting_time}
        meetingDay={group.meeting.meeting_day}
      />
    ) : null;

  const primaryLink =
    showPrimaryCta && primaryCtaLink
      ? formatCTAUrl(
          primaryCtaAction,
          primaryCtaLink,
          primaryCTASubject,
          primaryCTAMessage
        )
      : null;

  const secondaryLink =
    showSecondaryCta && secondaryCtaLink
      ? formatCTAUrl(
          secondaryCtaAction,
          secondaryCtaLink,
          secondaryCTASubject,
          secondaryCTAMessage
        )
      : null;

  const primaryCTA =
    showPrimaryCta && primaryCtaLink ? (
      <ButtonCTA
        label={
          isPrimaryCTADefaultLabel
            ? primaryCtaLink.label
            : primaryCTACustomLabel
        }
        url={primaryLink}
        className={
          "dce--groups-card-details-item--primary-cta dce--groups-card--cta"
        }
        target={openLinkInNewTab ? "_blank" : "_self"}
      />
    ) : null;

  const secondaryCta =
    showSecondaryCta && secondaryCtaLink ? (
      <ButtonCTA
        label={
          isSecondaryCTADefaultLabel
            ? secondaryCtaLink.label
            : secondaryCTACustomLabel
        }
        url={secondaryLink}
        className={
          "dce--groups-card-details-item--secondary-cta dce--groups-card--cta"
        }
        target={openLinkInNewTab ? "_blank" : "_self"}
      />
    ) : null;

  const avatar = showImage ? (
    <div className={"dce--groups-cards-card_header-image"}>{banner}</div>
  ) : null;

  const name = showName ? <h2>{group.name}</h2> : null;

  const primaryBadge = showPrimaryBadge
    ? Primitives.find((i) => i.visibilitySetting === primaryBadgeField)
    : null;
  const secondaryBadge = showSecondaryBadge
    ? Primitives.find((i) => i.visibilitySetting === secondaryBadgeField)
    : null;

  const badges =
    primaryBadge || secondaryBadge ? (
      <PrimitiveBadges>
        {showPrimaryBadge && primaryBadge ? (
          <Badge
            group={group}
            label={primaryBadge.label}
            name={group.primitives[primaryBadgeField]}
            className={"dce--groups-cards-card_badge-primary"}
          />
        ) : null}
        {showSecondaryBadge && secondaryBadge ? (
          <Badge
            group={group}
            label={secondaryBadge.label}
            name={group.primitives[secondaryBadgeField]}
            className={"dce--groups-cards-card_badge-secondary"}
          />
        ) : null}
      </PrimitiveBadges>
    ) : null;

  const cardContent = hasCardContent ? (
    <div className={"dce--groups-cards-card_content"}>
      {badges}
      <div className={"dce--groups-cards-card_content-body"}>
        {name}
        {address}
        {meeting}
        {descriptionElement}

        <div className={"dce--groups-card-details-container"}>{primitives}</div>
        {primaryCTA}
        {secondaryCta}
      </div>
    </div>
  ) : null;

  return (
    <div
      className={classnames(
        "dce--groups-cards-card",
        "dce--groups-cards-card--hover-effect--" + props.hoverEffect,
        {
          "dce--groups-cards-card_cursor_pointer": showPopup ? true : false,
        }
      )}
      onMouseUp={onCardClick}
    >
      <div className={"dce--groups-cards-card_header"}>{avatar}</div>
      {cardContent}
      <div className={"groups-cards-card--hover-effect--accent-simple"} />
      <div className={"groups-cards-card--hover-effect--accent-expandable"} />
    </div>
  );
};

CardGroup.defaultProps = {
  hoverEffect: "zoom",
};

CardGroup.propTypes = {
  onSelectedEvent: PropTypes.func,
  hoverEffect: PropTypes.string,
};
export default CardGroup;
