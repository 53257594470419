/**
 * Created by piotr.pozniak@thebeaverhead.com on 20/09/2024.
 */

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useCalendarStore } from "../../../../hooks/redux/calendar";
import { useEventsStore } from "../../../../hooks/redux/events";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const SearchBar = ({ onSearch }) => {
  const widgetSettings = useContext(WidgetSettingsContext);

  const [expanded, setExpanded] = useState(
    Boolean(widgetSettings["searchExpandedDefault"])
  );
  const { calendar } = useCalendarStore();
  const { events } = useEventsStore();

  const isLoading = calendar.fetch || events.fetch;

  const searchInputRef = useRef();

  useEffect(() => {
    if (Boolean(widgetSettings["searchExpandedDefault"])) {
      setExpanded(true);
    }
  }, [widgetSettings]);

  /**
   *
   * @type {function(*): boolean}
   */
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      console.log("search");
      onSearch(searchInputRef.current.value);

      return true;
    },
    [searchInputRef.current, onSearch]
  );

  /**
   *
   * @type {function(*): boolean}
   */
  const onReset = useCallback((e) => {
    onSearch("");

    return true;
  }, []);

  /**
   *
   * @type {(function(): void)|*}
   */
  const toggleFilters = useCallback(() => {
    if (Boolean(widgetSettings["searchExpandedDefault"])) {
      setExpanded(true);
      return false;
    }
    setExpanded(!expanded);
  }, [expanded, widgetSettings]);

  if (!onSearch || !Boolean(widgetSettings["searchEnabled"])) {
    return null;
  }

  return (
    <form
      className={classNames("rev-cal-search-block", {
        "rev-cal-search-block_expanded": expanded,
      })}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <div
        className={"rev-cal-expand-search-block-btn"}
        onClick={toggleFilters}
      >
        <i className={"material-icons"}>search</i>{" "}
      </div>
      <input
        ref={searchInputRef}
        type={"search"}
        className={"rev-cal-search-block-input"}
        placeholder={widgetSettings["searchInputPlaceholder"]}
        disabled={isLoading}
      />
      <button
        type={"reset"}
        className={"rev-cal-expand-clear-block-btn"}
        disabled={isLoading}
      >
        &times;
      </button>
      <button
        type={"submit"}
        className={"rev-cal-search-block-btn"}
        disabled={isLoading}
      >
        Search
      </button>
    </form>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func,
};

export default SearchBar;
