/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/06/2021.
 */

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as eventsActions from "../../actions/events";
import {
  fetchEvents,
  resetEventsCollection,
  RSVPEvent,
} from "../../actions/events";

export function useEventsStore() {
  const dispatch = useDispatch();
  const _events = useSelector((store) => store.events);

  const fetchEvent = useCallback(
    async (slug) => await dispatch(eventsActions.fetchEvent(slug)),
    [dispatch]
  );

  const fetchEvents = useCallback(
    async (
      calendarUUID,
      from,
      to,
      limit,
      page,
      filters,
      preservePreviousEvents = false,
      params = {}
    ) =>
      await dispatch(
        eventsActions.fetchEvents(
          calendarUUID,
          from,
          to,
          limit,
          page,
          filters,
          preservePreviousEvents,
          0,
          params
        )
      ),
    [dispatch]
  );

  const fetchFeaturedEvents = useCallback(
    async (
      calendarUUID,
      from,
      to,
      limit,
      page,
      filters,
      preservePreviousEvents,
      params
    ) =>
      await dispatch(
        eventsActions.fetchFeaturedEvents(
          calendarUUID,
          from,
          to,
          limit,
          page,
          filters,
          preservePreviousEvents,
          params
        )
      ),
    [dispatch]
  );

  const RSVPEvent = useCallback(
    async (calendarUUID, eventSlug, login, password, status) =>
      await dispatch(
        eventsActions.RSVPEvent(
          calendarUUID,
          eventSlug,
          login,
          password,
          status
        )
      ),
    [dispatch]
  );

  const resetEventsCollection = useCallback(
    () => dispatch(eventsActions.resetEventsCollection()),
    [dispatch]
  );

  const searchEvents = useCallback(
    async (calendarUUID, query, limit, page, filters, preservePreviousEvents) =>
      await dispatch(
        eventsActions.searchEvents(
          calendarUUID,
          query,
          limit,
          page,
          filters,
          preservePreviousEvents
        )
      ),
    [dispatch]
  );

  return {
    events: _events,
    fetchEvents,
    fetchFeaturedEvents,
    resetEventsCollection,
    RSVPEvent,
    fetchEvent,
    searchEvents,
  };
}
