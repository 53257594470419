/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/09/2024
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const PrivateEventBadge = (props) => {
  return (
    <i
      className={classNames("material-icons private-event", props.className)}
      data-placement={"left"}
      data-toggle="tooltip"
      data-original-title="This is a private event, that comes from private calendar. This icon is only to indicate this information and it is not visible on your website."
    >
      lock
    </i>
  );
};

PrivateEventBadge.defaultProps = {
  className: "",
};

PrivateEventBadge.propTypes = {
  className: PropTypes.string,
};

export default PrivateEventBadge;
