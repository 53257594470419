/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023.
 */

import React, { useState, useEffect, useMemo, useCallback } from "react";

import PageTemplate from "./../templates/PageTemplate";
import TableRow from "./QueueWorkersPage/TableRow";
import LoadingIndicator from "../../calendar/components/LoadingIndicator";
import { useQueueWorkersStore } from "../../../hooks/redux/queueWorkers";
import { useAppStore } from "../../../hooks/redux/app";
import QueueTabs from "../templates/QueueJobsPageTemplate/QueueTabs";

const WorkerOptions = [
  {
    label: "Sync jobs",
    value: "Integration",
  },
  {
    label: "SyncOne jobs",
    value: "SyncOne",
  },
  {
    label: "Embedding jobs",
    value: "Embedding",
  },
  {
    label: "Update ICS",
    value: "ICSUpdate",
  },
  {
    label: "Clean ICS",
    value: "ICSDelete",
  },
];

const QueueWorkersPage = (props) => {
  const { queueWorkers, fetchQueueWorkers, runWorker, terminateWorker } =
    useQueueWorkersStore();
  const { clearErrors } = useAppStore();

  useEffect(() => {
    clearErrors();
    fetchQueueWorkers();
    return () => {
      clearErrors();
    };
  }, []);

  const loading =
    queueWorkers.fetch || queueWorkers.run || queueWorkers.terminate;

  /**
   *
   * @type {(function())|*}
   */
  const onStartNewWorker = useCallback(
    (jobType) => async (e) => {
      e.preventDefault();
      await runWorker(jobType);
      /*sleep(500).then(() => {
      fetchQueueWorkers();
    });*/
    },
    []
  );

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onTerminateWorker = useCallback(
    (workerId) => {
      terminateWorker(workerId);
    },
    [queueWorkers]
  );

  /**
   *
   * @type {(function(): Promise<void>)|*}
   */
  const onRefresh = useCallback(async () => {
    await fetchQueueWorkers();
  }, []);

  const list = useMemo(
    () =>
      queueWorkers.collection.map((item, idx) => {
        return (
          <TableRow
            disabled={loading}
            key={item.id}
            worker={item}
            onTerminate={onTerminateWorker}
            /*onDelete={onCouponDelete}
          onEdit={onShowEditCoupon}*/
          />
        );
      }),
    [queueWorkers.collection, loading]
  );

  const loadingIndicator = loading ? <LoadingIndicator /> : null;

  const workerOptions = WorkerOptions.map((option) => (
    <a
      key={option.value}
      href="#"
      className="dropdown-item"
      onClick={onStartNewWorker(option.value)}
    >
      {option.label}
    </a>
  ));

  const runWorkerButton = queueWorkers.run ? (
    <LoadingIndicator />
  ) : (
    <div className={"d-inline"}>
      <a
        key={"startNewWorker"}
        href="#"
        className="btn btn-primary btn-rounded pull-right d-flex"
        /*onClick={onStartNewWorker}*/
        data-toggle={"dropdown"}
      >
        <i className="material-icons">add</i> Start new worker
      </a>
      <div className="dropdown-menu dropdown-menu-right ">{workerOptions}</div>
    </div>
  );

  const refreshButton = !loading ? (
    <a
      key={"refreshWorkersList"}
      href="#"
      className="btn btn-secondary btn-rounded pull-right m-r-5 d-flex"
      onClick={onRefresh}
    >
      <i className="material-icons">refresh</i> Refresh
    </a>
  ) : null;

  const table = queueWorkers.collection.length ? (
    <table className="table table-striped custom-table">
      <thead>
        <tr>
          <th width={"5%"}>Id</th>
          <th width={"5%"}>PID</th>
          <th width={"10%"}>Job Type</th>
          <th width={"15%"}>Started</th>
          <th width={"15%"}>Modified</th>
          <th>Terminated</th>
          <th className="text-right">Action</th>
        </tr>
      </thead>
      <tbody>{list}</tbody>
    </table>
  ) : null;

  return (
    <PageTemplate
      sidebarId="queueWorkers"
      title={"Queue Workers"}
      icon={"engineering"}
      headerControls={[runWorkerButton, refreshButton]}
    >
      <QueueTabs />
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            {table}
            {loadingIndicator}
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default QueueWorkersPage;
