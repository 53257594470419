/**
 * Created by piotr.pozniak@thebeaverhead.com on 02/09/2024
 */

import React, { useContext, useEffect } from "react";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import { useCalendarStore } from "../../../../hooks/redux/calendar";

/**
 *
 * @param location
 * @returns {{address: {"@type": string}, "@type": string}|null|{address: {"@type": string, name: string, text: string}, "@type": string}|{"@type": string, url: String}}
 */
const formatLocation = (location) => {
  if (location instanceof String) {
    if (location.match(/http/)) {
      return {
        "@type": "Place",
        url: location,
      };
    }

    const chunks = location.split(",");
    const name = chunks[0];
    const address = chunks.slice(1).join(", ");
    return {
      "@type": "Place",
      address: {
        "@type": "Text",
        name,
        text: address,
      },
    };
  }

  const obj = {
    "@type": "Place",
    address: {
      "@type": "PostalAddress",
    },
  };

  if (location) {
    if (location.name) {
      obj.name = location.name;
    }

    if (location.city) {
      obj.address.addressLocality = location.city;
    }

    if (location.line_1) {
      obj.address.streetAddress = location.line_1;
    }

    if (
      (location.street_address && !location.line_1) ||
      location.line_1 != location.street_address
    ) {
      obj.address.streetAddress = location.street_address;
    }

    if (location.line_2) {
      obj.address.addressLocality = location.line_2;
    }

    if (location.zip) {
      obj.address.postalCode = location.zip;
    }

    return obj;
  }

  return null;
};
export const forceClearStructuredHead = () => {
  const data = document.getElementsByClassName("structured");

  for (let i = data.length - 1; i >= 0; --i) {
    data[i].remove();
  }
};

const jsonToHead = (id, json) => {
  let el = document.createElement("script");
  el.type = "application/ld+json";
  el.id = id;
  el.text = JSON.stringify(json);

  document.querySelector("head").appendChild(el);
};

const EventSchema = ({ events }) => {
  const calendarSettingsContext = useContext(WidgetSettingsContext);
  const { calendar } = useCalendarStore();

  useEffect(() => {
    try {
      events.map((i, idx) => {
        let organizer = null;
        let location = formatLocation(i.location);
        const descriptionDoc = new DOMParser().parseFromString(
          i.description,
          "text/html"
        );
        const description = descriptionDoc.body.textContent || "";

        if (calendarSettingsContext["eventDetailsShowOrganizer"] === 1) {
          organizer = {
            "@type": "Person",
            name: i.organizer.name,
          };
        }

        return jsonToHead(i.id, {
          "@context": "http://schema.org",
          "@type": "Event",
          name: i.name,
          startDate: new Date(i.start_time * 1000),
          endDate: new Date(i.end_time * 1000),
          organizer,
          location,
          identifier: {
            "@type": "PropertyValue",
            name: "Event ID",
            value: i.id,
          },

          image: i.cover_image,
          description,
          url: `${process.env.BASE_URL}/e/${calendar.model.slug}/${i.slug}`,
        });
      });
    } catch (e) {
      console.error("EventsMetadata", e);
    }
  }, [events]);

  return <></>;
};

EventSchema.defaultProps = {};

EventSchema.propTypes = {};

export default EventSchema;
