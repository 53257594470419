/**
 * Created by piotr.pozniak@thebeaverhead.com on 24/10/2018.
 */

import React, { useCallback } from "react";

import { Link } from "react-router-dom";
import SidebarTab from "./SidebarTab";
import ClipboardCopyInput from "../ClipboardCopyInput";
import LoadingIndicator from "../../../calendar/components/LoadingIndicator";
import SuccessAlert from "../SuccessAlert";
import PropTypes from "prop-types";
import ApiError from "../ApiError";
import { calendarFeaturesCheck, groupFeaturesCheck } from "../../tools";
import { useCalendarsStore } from "../../../../hooks/redux/calendars";
import { PLAN_NAMES } from "../../../../consts";
import InstallExpandableTab from "./InstallExpandalbleTab";
import {
  getWixEmbeddedScript,
  loaderScriptUrl,
} from "../../templates/WidgetPageTemplate/InstallTab/helpers";
import { useCurrentOrganizationStore } from "../../../../hooks/redux/currentOrganization";
import Accordion from "./Acordion";
import RadioInput from "./RadioInput";
import QRCode from "./QRCode";
import { useAppStore } from "../../../../hooks/redux/app";
import DismissableMessage from "../../templates/WidgetPageTemplate/InstallTab/DismissableMessage";
import ShareableUrl from "./ShareableUrl";

const dismissableMessage =
  "Sometimes, new embeds do not show up in on builder pages until published. We suggest using a Test Page or Private Mode to set things up. Once things are set as you want them, you can update the actual page or make your private page public.";

const InstallTab = ({ widgetTypeName = "Widget", ...props }) => {
  const { widgetModel, widget } = props;
  const widgetType = widget.model.widget_type;
  const { currentOrganization } = useCurrentOrganizationStore();
  const { calendars } = useCalendarsStore();

  const { dismissedAlerts } = useAppStore();
  const dismissableMessageId = `embed_warning-${widgetType}`;
  const hasEmbedInfo =
    !dismissedAlerts.collection.includes(dismissableMessageId);

  const featureCheck =
    widgetModel && widgetModel.integration && !widget.update
      ? widgetType === "calendar"
        ? calendarFeaturesCheck
        : groupFeaturesCheck
      : null;

  const publishIssues = featureCheck
    ? featureCheck(
        { ...widgetModel, available: 1 },
        currentOrganization,
        calendars
      )
    : [];

  const isPublishDisabled =
    widget.update || (!widget.model.available && publishIssues.length)
      ? true
      : false;

  const publishRestrictionWarning =
    !widgetModel.available && publishIssues.length ? (
      <div className={"alert alert-danger mt-3 p-2"}>
        <i className={"text-danger m-r-5 material-icons icon-sm"}>warning</i>
        You cannot publish this {widgetType} widget because:
        <ul className={"pl-3 "}>
          {publishIssues.map((i, idx) => (
            <li key={`cv-${idx}`}>{i}</li>
          ))}
        </ul>
        {props.recommendedPlan && (
          <span>
            Click here to upgrade to the minimum required plan{" "}
            <strong>{PLAN_NAMES[props.recommendedPlan]}</strong>.
          </span>
        )}
        <div className={"d-flex justify-content-center"}>
          <Link to={"/billing"}>
            <button className={"btn btn-success btn-sm"}>Upgrade now</button>
          </Link>
        </div>
      </div>
    ) : null;
  // NotificationComponent.addNotification({
  //         content: (props) => (
  //           <NotificationPopup {...props}>
  //             <p className="notification-title">
  //               You cannot publish this calendar because:
  //             </p>
  //             <ul className={"notification-message"}>
  //               {issues.map((i, idx) => (
  //                 <li key={`cv-${idx}`}>{i}</li>
  //               ))}
  //             </ul>
  //           </NotificationPopup>
  //         ),
  //         title: "You cannot publish this calendar because:",
  //         //message: formatError(calendar.updateError).join(""),
  //         type: "danger",
  //         insert: "top",
  //         container: "top-center",
  //         animationIn: ["animated", "fadeIn"],
  //         animationOut: ["animated", "fadeOut"],
  //         dismiss: {
  //           duration: 5000,
  //           onScreen: true,
  //         },
  //       });

  /**
   *
   * @type {(function(*): void)|*}
   */
  const onChangeStatus = useCallback(
    (field) => (e) => {
      props.onUpdateStatus(!(widget.model.available * 1) ? 1 : 0);
    },
    [props.onUpdateStatus, widget]
  );

  /**
   *
   * @type {function(*): function(*): void}
   */
  const onChangeStatusButton = useCallback(
    (e) => {
      onChangeStatus("available")(e);
    },
    [onChangeStatus]
  );

  const widgetTypeNamePrintable = widgetTypeName.toLowerCase();

  const publishCard = (
    <Accordion
      title={"Publish"}
      subtitle={`Your ${widgetTypeNamePrintable} is ${
        widget.model.available * 1 == 0 ? "is not" : ""
      } published.`}
      keepExpanded={true}
    >
      <RadioInput
        fieldName={"available"}
        label={"Make this widget available to others"}
        onChange={onChangeStatus}
        checked={widgetModel.available}
        disabled={isPublishDisabled}
        tooltip={
          widget.model.available * 1 === 0
            ? {
                message: `In order to share your ${widgetTypeNamePrintable} and make it accessible by
              others, change it status to published. You can make it here using
              the toggle button.`,
              }
            : null
        }
      />
      {publishRestrictionWarning}

      {/*<RadioInput
          fieldName={"available"}
          label={"Published"}
          onChange={onFilteringCheckboxChange}
          checked={
            widgetModel && widgetModel.available ? widgetModel.available * 1 : 0
          }
        />*/}
      {widget.model.available && props.shareableUrl ? (
        <ShareableUrl
          url={props.shareableUrl}
          widgetModel={widgetModel}
          widgetTypeName={widgetTypeNamePrintable}
          onUpdateSettings={props.onUpdateWidgetSettings}
          onSubmit={props.onSubmit}
        />
      ) : (
        <div />
      )}

      <SuccessAlert className={"p-2"}>
        {widget.updateSuccess ? "Changes have been saved!" : null}
      </SuccessAlert>
    </Accordion>
  );

  const noDomainsWarning = !props.hasDomains ? (
    <div
      className="alert alert-danger alert-dismissible fade show alert-tab"
      role="alert"
    >
      Assign <Link to={"/domains"}>website</Link> before embedding this{" "}
      {widgetTypeNamePrintable}.
    </div>
  ) : null;

  const installScripTag = `<script async id="dce-embeddable-script" src="${loaderScriptUrl}"></script>`;

  const installDivTag = `<div class="dce-${widgetType}" id="${widget.model.uuid}"></div>`;

  const installIframeTag = `<div class="dce-${widgetType}" id="${widget.model.uuid}" iframe="true" slug="${widget.model.slug}"></div>`;

  const wixHeadScript = `<script async id="dce-embeddable-script" src="${loaderScriptUrl}" wix="true"></script>`;

  const wixEmbeddedScript = getWixEmbeddedScript(widgetType, widget.model.uuid);

  const unpublishedWarning = (
    <div
      className={
        "alert alert-warning fade show d-flex align-items-center flex-column p-2"
      }
    >
      This widget is unpublished. Publish your widget first.
      <button
        className="btn btn-primary btn-sm m-2"
        onClick={onChangeStatusButton}
      >
        Publish
      </button>
    </div>
  );

  const quickSetupTab = widget.model.available ? (
    <form action="#">
      {noDomainsWarning}

      <div className="col-xs-12">
        <p>
          <em>
            This option is not ideal if you are using multiple{" "}
            {widgetTypeNamePrintable}s per website page. If you wish to try the
            Quick setup anyway, copy/paste the following code where you would
            like the {widgetTypeNamePrintable} to appear on your website.
          </em>
        </p>

        <ClipboardCopyInput
          id={"embeddable_script_quick"}
          value={installScripTag + installDivTag}
          placeholder={`${widgetTypeName} embeddable script`}
        />
        <p className="text-center">
          <br />
          OR
          <br />
        </p>

        <p>
          <em>
            <strong className={"text-danger"}>EXPERIMENTAL</strong> In case your
            website theme is using an aggressive stylesheet and the{" "}
            {widgetTypeNamePrintable}
            does not display nicely on your website, copy/paste the following
            code which will embed the {widgetTypeNamePrintable} in the iframe on
            your website:
          </em>
        </p>
        <ClipboardCopyInput
          id={"embeddable_div_iframe_quick"}
          value={installScripTag + installIframeTag}
          placeholder={`${widgetTypeName} embeddable div with iframe`}
        />
      </div>
    </form>
  ) : (
    unpublishedWarning
  );

  const wixSetupTab = widget.model.available ? (
    <form action="#">
      {noDomainsWarning}
      <ol>
        <li>
          <div className="col-xs-12">
            <p>
              <strong className={"text-danger"}>EXPERIMENTAL</strong>
              <br />
              <em>
                Add the snippet below to &lt;head&gt; of your wix page. Add
                &gt;&gt; Embed &gt;&gt; Marketing Tools &gt;&gt; Custom Code. If
                you use our {widgetTypeNamePrintable} in many places, add it to
                all sites, if it's only available on one page, add it to that
                page only. Mark it <strong>Essential</strong>.
              </em>
            </p>

            <ClipboardCopyInput
              id={"wix-head-script"}
              value={wixHeadScript}
              placeholder={"Wix head script"}
            />
          </div>
        </li>
        <li>
          <div className="col-xs-12">
            <p>
              <em>
                On a desired page, where you'd like to display the{" "}
                {widgetTypeNamePrintable}, add an embedded code by copying the
                following:
              </em>
            </p>

            <ClipboardCopyInput
              id={"wix-embed-script"}
              value={wixEmbeddedScript}
              placeholder={"Wix head script"}
              tag="textarea"
            />
          </div>
        </li>
      </ol>
    </form>
  ) : (
    unpublishedWarning
  );

  const advancedSetupTab = widget.model.available ? (
    <form action="#">
      {noDomainsWarning}
      <DismissableMessage
        message={dismissableMessage}
        id={dismissableMessageId}
      />
      <ol>
        <li>
          <div className="col-xs-12">
            <p>
              <em>
                To install the {widgetTypeNamePrintable} on your website, copy
                the following code into the &lt;head&gt; of your website:
              </em>
            </p>

            <ClipboardCopyInput
              id={"embeddable_script"}
              value={installScripTag}
              placeholder={"Calendar embeddable script"}
            />
            <p>
              <br />
            </p>
          </div>
        </li>

        <li>
          <ol>
            <li>
              <div className="col-xs-12">
                <p>
                  <em>
                    Then put the following HTML5 object where you want the
                    {widgetTypeNamePrintable} to appear:
                  </em>
                </p>
                <ClipboardCopyInput
                  id={"embeddable_div"}
                  value={installDivTag}
                  placeholder={`${widgetTypeName} embeddable div`}
                />
                <p align="center">
                  <br />
                  OR
                  <br />
                </p>
              </div>
            </li>
            <li>
              <div className="col-xs-12">
                <p>
                  <em>
                    <strong className={"text-danger"}>EXPERIMENTAL</strong>
                    In case your website theme is using an aggressive stylesheet
                    and the {widgetTypeNamePrintable} does not display nicely on
                    your website, copy/paste the following code which will embed
                    the {widgetTypeNamePrintable}
                    in the iframe on your website:
                  </em>
                </p>
                <ClipboardCopyInput
                  id={"embeddable_div_iframe"}
                  value={installIframeTag}
                  placeholder={`${widgetTypeName} embeddable div with iframe`}
                />
              </div>
            </li>
          </ol>
        </li>
      </ol>
    </form>
  ) : (
    unpublishedWarning
  );

  const footer = !props.disabled ? (
    <div>
      <ApiError
        error={widget.updateError}
        defaultErrorMessage={"Could not save your settings. Please try again"}
      />
      {/*<div className="text-right">
        <button
          disabled={props.disabled}
          type="submit"
          className="btn btn-primary"
          onClick={props.onSubmit}
        >
          Update
        </button>
      </div>*/}
    </div>
  ) : (
    <LoadingIndicator />
  );

  return (
    <SidebarTab id="install_tab" show={false} footer={footer}>
      {publishCard}

      <InstallExpandableTab
        title="Embed on website"
        icon="code"
        key={"it-as"}
        subheader={
          hasEmbedInfo && (
            <span className={"d-flex"}>
              <i className={"material-icons text-info p-0 m-0"}>info</i>
              <span className={"m-1"}>
                Read instructions before installing&nbsp;👇
              </span>
            </span>
          )
        }
      >
        {advancedSetupTab}
      </InstallExpandableTab>

      <InstallExpandableTab title="Embed on Wix" icon="schedule" key={"it-wix"}>
        {wixSetupTab}
      </InstallExpandableTab>

      <InstallExpandableTab
        title="Quick website embed"
        icon="science"
        subheader={
          <span className={"d-flex"}>
            <i className={"material-icons text-warning p-0 m-0"}>warning</i>
            <span className={"m-1"}>Not recommended</span>
          </span>
        }
      >
        {quickSetupTab}
      </InstallExpandableTab>
    </SidebarTab>
  );
};

InstallTab.propTypes = {
  // form fields and errors
  widget: PropTypes.object.isRequired,
  widgetModel: PropTypes.object,

  errors: PropTypes.object,

  onSubmit: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,

  isNew: PropTypes.bool,
  hasDomains: PropTypes.bool,

  disabled: PropTypes.bool,

  widgetTypeName: PropTypes.string,
  widgetType: PropTypes.string,
  shareableUrl: PropTypes.string,

  recommendedPlan: PropTypes.string,
  onUpdateWidgetSettings: PropTypes.func,
};

export default InstallTab;
