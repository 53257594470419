/**
 * Created by piotr.pozniak@thebeaverhead.com on 07/02/2023
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

const Tabs = [
  {
    label: "Queue workers",
    path: "/queue-workers",
    id: "queue-workers",
  },
  {
    label: "Sync jobs",
    path: "/sync-jobs/1",
    id: "sync-jobs",
  },
  {
    label: "Webhooks jobs",
    path: "/webhook-jobs/1",
    id: "webhook-jobs",
  },
  {
    label: "ICS Update",
    path: "/ics-update-jobs/1",
    id: "ics-update-jobs",
  },
  {
    label: "ICS clean",
    path: "/ics-clean-jobs/1",
    id: "ics-clean-jobs",
  },
  {
    label: "Embedding jobs",
    path: "/embedding-jobs/1",
    id: "embedding-jobs",
  },
];

const QueueTabs = (props) => {
  const location = useLocation();

  const currentTabName = useMemo(() => {
    const locationChunks = location.pathname.split("/");
    return locationChunks[1];
  }, [location.pathname]);

  const tabs = useMemo(
    () =>
      Tabs.map((tab) => {
        return (
          <li className="nav-item" key={tab.path}>
            <Link
              className={classNames("nav-link", {
                active: currentTabName === tab.id,
              })}
              to={tab.path}
            >
              {tab.label}
            </Link>
          </li>
        );
      }),
    [location.pathname]
  );

  return <ul className={"nav nav-tabs"}>{tabs}</ul>;
};

QueueTabs.defaultProps = {};

QueueTabs.propTypes = {};

export default QueueTabs;
