/**
 * Created by piotr.pozniak@thebeaverhead.com on 06/01/2019.
 */

import React, { useContext, useState } from "react";

import moment from "moment";
import LoadingIndicator from "../LoadingIndicator";
import PropTypes from "prop-types";
import { CalendarHeader as MonthlyCalendarHeader } from "./CalendarHeader";
import CalendarHeader from "../CalendarHeader";
import classnames from "classnames";
import CalendarDay from "./CalendarDay";
import Events from "../EventsList/Events";
import EventsListModal from "../EventsList/EventsListModal";
import { CT, PT } from "../../../../consts";
import PoweredBy from "../PoweredBy";
import { openEventPage } from "../../../../helpers/calendar";
import SearchInput from "../AiSearch/SearchInput";
import useAiSearch from "../../../../hooks/useAiSearch";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";

const MonthlyView = (props) => {
  const [state, setState] = useState({
    selectedDay: moment() /*.utcOffset(0, true)*/,
    showDayEventsInModal: false,
    selectedEvent: null,
  });

  const { calendar, events } = props;

  const { hasAIEnabled } = useAiSearch(calendar.model.integration);
  const widgetSettings = useContext(WidgetSettingsContext);

  const openChurchCenter =
    Number.parseInt(widgetSettings["generalGoToChurchCenter"]) === 1;

  const openLinkInNewTab =
    Number.parseInt(widgetSettings["openLinksNewTab"]) === 1;

  const hideNavigation =
    Number.parseInt(widgetSettings["mcHideEventsList"]) === 1;
  const showEventsCountTooltip =
    Number.parseInt(widgetSettings["mcShowEventsCounter"]) === 1;
  const showFullNamesTooltip =
    Number.parseInt(widgetSettings["mcShowEventsDetailedInfo"]) === 1;
  const showPills = Number.parseInt(widgetSettings["mcShowEventsNames"]) === 1;
  const pillOpensEventDetails =
    Number.parseInt(widgetSettings["mcShowNamesShowsDetails"]) === 1;
  const isCompact = Number.parseInt(widgetSettings["mcCompactView"]) === 1;
  const useEventAccent =
    Number.parseInt(widgetSettings["generalUseEventAccent"]) === 1;

  // first day of the week set by the user (0 - 6, 0 - Sunday)
  const firstDayOfWeek = widgetSettings["firstDayOfWeek"];

  // current month first day, to know what day of the week is it (1 - 7, 1 - Sunday)
  const firstDayOfMonth =
    moment(props.selectedDate).startOf("month").format("E") * 1;
  // to know how many days are in the month (1 - 31)
  const lastDayOfMonth =
    moment(props.selectedDate).endOf("month").format("D") * 1;

  const recurringShowBadge =
    Number.parseInt(widgetSettings["recurringShowBadge"]) === 1;
  const featuredShowBadge =
    Number.parseInt(widgetSettings["featuredShowBadge"]) === 1;
  const isEventsLimited =
    Number.parseInt(widgetSettings["isEventsLimited"]) === 1;
  const limitedNumberOfEvents = Number.parseInt(
    widgetSettings["limitedNumberOfEvents"]
  );

  /**
   * When user clicks on event pill
   * @param event
   */
  const onSelectEvent = (event) => {
    if (openChurchCenter) {
      openEventPage(event, openLinkInNewTab);
      return;
    }

    if (!pillOpensEventDetails) {
      return;
    }
    setState({
      ...state,
      showDayEventsInModal: true,
      selectedEvent: event,
    });
  };

  /**
   *
   * @param day
   */
  const onSelectDay = (day) => () => {
    const data = { selectedDay: day, selectedEvent: null };

    if (pillOpensEventDetails) {
      data.showDayEventsInModal = true;
    }

    setState(data);
  };

  /**
   *
   * @param date
   */
  const onDateChange = (month) => {
    setState({ selectedDay: state.selectedDay.add(month, "month") });

    props.onSelectedDateChange(month);
  };

  /**
   *
   */
  const onCloseEventsListModal = () => {
    setState({ ...state, showDayEventsInModal: false });
  };

  const workingDate = moment(props.selectedDate);

  const daysInWeek = 7;
  const firstWeekOffset =
    (daysInWeek - (firstDayOfWeek - firstDayOfMonth)) % daysInWeek;
  // how many rows in the calendar will be. Usually it's 5 rows but sometimes, depending
  // on the first day of the month, may be that month's weeks applies to 6 yearly weeks
  const weeksInMonth = Math.ceil(
    (firstWeekOffset + lastDayOfMonth) / daysInWeek
  );

  const watermark =
    calendar.model && calendar.model.is_watermarked ? <PoweredBy /> : null;

  const eventsContainer = (
    <div>
      <table className={classnames("", { compact: isCompact })}>
        <MonthlyCalendarHeader
          isCompact={isCompact}
          firstDayOfWeek={firstDayOfWeek}
        />

        <tbody>
          {[...Array(weeksInMonth).keys()].map((i, idx) => {
            return (
              <tr key={"week-" + idx}>
                {[...Array(7).keys()].map((j, jdx) => {
                  const dayIdx = idx * 7 + jdx; // + firstDayOfWeek;
                  const day = dayIdx - firstDayOfMonth;

                  if (
                    dayIdx < firstWeekOffset ||
                    dayIdx >= lastDayOfMonth + firstWeekOffset
                  ) {
                    return (
                      <td
                        key={"day-" + day}
                        className={classnames("", {
                          day: day <= lastDayOfMonth,
                        })}
                      />
                    );
                  }

                  const momentDay = moment(workingDate); /*.utcOffset(0, true)*/
                  workingDate.add(1, "days");

                  return (
                    <CalendarDay
                      key={"day-" + day}
                      day={momentDay}
                      events={events}
                      embedded={props.embedded}
                      onSelectDay={onSelectDay}
                      selectedDay={state.selectedDay}
                      showPills={showPills}
                      isCompact={isCompact}
                      showEventsCountTooltip={showEventsCountTooltip}
                      showFullNamesTooltip={showFullNamesTooltip}
                      pillOpensEventDetails={pillOpensEventDetails}
                      onEventClick={onSelectEvent}
                      useEventAccent={useEventAccent}
                      showRecurring={recurringShowBadge}
                      showFeatured={featuredShowBadge}
                      isEventsLimited={isEventsLimited}
                      limitedNumberOfEvents={limitedNumberOfEvents}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  const eventsListContent =
    events.fetch || !props.initialized ? <LoadingIndicator /> : eventsContainer;

  const calendarID = "dce_calendar__" + calendar.model.uuid;
  const loading =
    calendar.rsvp || calendar.fetch || calendar.delete || calendar.update;

  const selectedDayEvents = events.collection.filter((e) => {
    return state.selectedDay.isBetween(
      moment(e.start_time * 1000) /*.utc()*/,
      moment(e.end_time * 1000) /*.utc()*/,
      "day",
      "[]"
    );
  });

  const eventsList =
    !hideNavigation && !events.fetch ? (
      <Events
        events={selectedDayEvents}
        calendarSlug={calendar.model.slug}
        embedded={props.embedded}
        ccbAddress={calendar.model.integration.address}
        onRSVPEvent={props.onRSVPEvent}
        loading={loading}
        error={props.calendar.rsvpError}
        onLoadMore={props.onLoadMore}
        hasMore={events.collection.length !== events.totalRows}
        calendarID={calendarID}
      />
    ) : null;

  const selectedDayEventsList =
    state.showDayEventsInModal && selectedDayEvents.length ? (
      <EventsListModal
        calendar={props.calendar}
        events={state.selectedEvent ? [state.selectedEvent] : selectedDayEvents}
        embedded={props.embedded}
        closeOpenedEventsDetails={onCloseEventsListModal}
        ccbAddress={calendar.model.integration.address}
        onRSVPEvent={props.onRSVPEvent}
        loading={loading}
        error={props.calendar.rsvpError}
        currentDate={state.selectedDay}
      />
    ) : null;

  const search =
    hasAIEnabled && widgetSettings["aiEnabled"] ? (
      <SearchInput
        searchEvents={props.searchEvents}
        fetchEvents={props.fetchEvents}
      />
    ) : null;

  return (
    <div className={classnames(`full-calendar dce--${CT.monthly}`)}>
      <CalendarHeader
        disabled={events.fetch}
        calendar={calendar}
        currentDate={props.selectedDate}
        onChangeDate={onDateChange}
        events={events.collection}
        locations={events.locations}
        onUpcomingNavigationChange={props.onUpcomingNavigationChange}
        selectedFilters={props.selectedFilters}
        onChangeFilter={props.onChangeFilter}
        onSearch={props.onKeywordSearch}
      />
      {search}

      {selectedDayEventsList}
      {eventsListContent}
      {watermark}
      {eventsList}
    </div>
  );
};

MonthlyView.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  onSelectedDateChange: PropTypes.func.isRequired,
  onUpcomingNavigationChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  initialized: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
  previewType: PropTypes.oneOf(Object.values(PT)),
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  searchEvents: PropTypes.func.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  onKeywordSearch: PropTypes.func.isRequired,
};

export default MonthlyView;
