import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import WeekDay from "./WeekDay";
import Events from "../EventsList/Events";
import CalendarHeader from "../CalendarHeader";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";
import PropTypes from "prop-types";
import LoadingIndicator from "../LoadingIndicator";
import classnames from "classnames";
import RowModal from "../EventsList/RowModal";
import { CT, PT } from "../../../../consts";
import PoweredBy from "../PoweredBy";
import { openEventPage } from "../../../../helpers/calendar";
import SearchInput from "../AiSearch/SearchInput";
import useAiSearch from "../../../../hooks/useAiSearch";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import { firstDayOfWeek } from "../../../../helpers/date";
import DateRangeSelector from "./DateRangeSelector/DateRangeSelector";

const WeeklyView = (props) => {
  const { calendar, events } = props;

  const [state, setState] = useState({
    selectedDay: null,
    currentWeek: new Date(props.selectedDate),
    selectedEvent: null,
  });

  const { hasAIEnabled } = useAiSearch(calendar.model.integration);
  const widgetSettings = useContext(WidgetSettingsContext);
  const openChurchCenter =
    Number.parseInt(widgetSettings["generalGoToChurchCenter"]) === 1;

  const openLinkInNewTab =
    Number.parseInt(widgetSettings["openLinksNewTab"]) === 1;

  useDidMountEffect(() => {
    // todo remove moment

    props.onSelectedDateChange(moment(state.currentWeek));
  }, [state.currentWeek]);

  /**
   *
   * @param day
   */
  const onSelectDay = (day) => {
    setState({ ...state, selectedDay: day });
  };

  /**
   *
   * @param week
   */
  const onChangeWeek = (week) => {
    setState({ ...state, currentWeek: week, selectedDay: null });
  };

  const showDetailsAsModal =
    Number.parseInt(widgetSettings["generalOpenDetailsModal"]) === 1;
  const eventsNumberOption = widgetSettings["eventsNumberOption"];
  const eventsNumberCount = widgetSettings["eventsNumber"];

  const eventsLocation = widgetSettings["wwEventsLocation"];
  const allowChangingWeeks =
    Number.parseInt(widgetSettings["wwAllowChangingWeeks"]) === 1;
  const showEventIndication =
    Number.parseInt(widgetSettings["wwShowEventsIndication"]) === 1;
  const enforceMobile =
    Number.parseInt(widgetSettings["wwEnforceMobile"]) === 1;
  const useEventAccent =
    Number.parseInt(widgetSettings["generalUseEventAccent"]) === 1;
  const recurringShowBadge =
    Number.parseInt(widgetSettings["recurringShowBadge"]) === 1;
  const featuredShowBadge =
    Number.parseInt(widgetSettings["featuredShowBadge"]) === 1;

  const watermark =
    calendar.model && calendar.model.is_watermarked ? <PoweredBy /> : null;

  useEffect(() => {
    if (eventsNumberOption == "date_range") {
      if (eventsNumberCount && eventsNumberCount.indexOf(":") > -1) {
        const [start, end] = eventsNumberCount.split(":");
        const startDate = new Date(start);
        if (!startDate.isSameDay(state.currentWeek)) {
          setState({ ...state, currentWeek: startDate });
        }
      }
    }
  }, [eventsNumberCount]);

  /**
   *
   * @param event
   */
  const onSelectedEvent = (event) => {
    if (openChurchCenter) {
      openEventPage(event, openLinkInNewTab);
      return;
    }
    if (showDetailsAsModal) {
      setState({ ...state, selectedEvent: event });
    }
  };

  const calendarID = "dce_calendar__" + calendar.model.uuid;

  const firstDay = firstDayOfWeek(state.currentWeek);

  const calendarDays = [...Array(7).keys()].map((i, idx) => {
    const weekDay = new Date(
      new Date(firstDay).setDate(firstDay.getDate() + i)
    );

    return (
      <WeekDay
        key={calendarID + "-wdk-" + idx}
        calendar={calendar}
        events={events}
        day={weekDay}
        isSelected={
          state.selectedDay && state.selectedDay.isSame(weekDay, "day")
        }
        onSelectDay={onSelectDay}
        showEventDetails={eventsLocation != "events_list"}
        eventsLocation={eventsLocation}
        showEventPresenseIndication={showEventIndication}
        onSelectedEvent={onSelectedEvent}
        useEventAccent={useEventAccent}
        showRecurring={recurringShowBadge}
        showFeatured={featuredShowBadge}
      />
    );
  });

  const loading =
    calendar.rsvp || calendar.fetch || calendar.delete || calendar.update;

  const eventsList =
    eventsLocation == "events_list" && !events.fetch ? (
      <Events
        events={
          state.selectedDay
            ? events.collection.filter((e) => {
                return state.selectedDay.isBetween(
                  moment(e.start_time * 1000) /*.utc()*/,
                  moment(e.end_time * 1000) /*.utc()*/,
                  "day",
                  "[]"
                );
              })
            : events.collection
        }
        calendarSlug={calendar.model.slug}
        embedded={props.embedded}
        ccbAddress={calendar.model.integration.address}
        onRSVPEvent={props.onRSVPEvent}
        loading={loading}
        error={props.calendar.rsvpError}
        onLoadMore={props.onLoadMore}
        hasMore={events.collection.length != events.totalRows}
        calendarID={calendarID}
      />
    ) : null;

  const eventsListContent =
    events.fetch || !props.initialized ? <LoadingIndicator /> : eventsList;

  const onCloseOpenedEventsDetails = (rowId) => {
    setState({ ...state, selectedEvent: null });

    if (props.onClosedEventRowModal) {
      props.onClosedEventRowModal();
    }
  };

  const modalEvent = state.selectedEvent ? (
    <RowModal
      event={state.selectedEvent}
      showDetails={true}
      calendarSlug={calendar.model.slug}
      embedded={props.embedded}
      ccbAddress={calendar.model.integration.address}
      onRSVPEvent={props.onRSVPEvent}
      loading={props.loading}
      error={props.error}
      closeOpenedEventsDetails={onCloseOpenedEventsDetails}
      expandedRowSlug={state.selectedEvent.slug}
      expandAsAccordion={false}
      expandAsModal={true}
      calendarID={calendarID}
      showWatermark={calendar.model && calendar.model.is_watermarked}
    />
  ) : null;

  const search =
    hasAIEnabled && widgetSettings["aiEnabled"] ? (
      <SearchInput
        searchEvents={props.searchEvents}
        fetchEvents={props.fetchEvents}
        className={"weekly-calendar--search"}
      />
    ) : null;
  return (
    <div
      className={classnames(`weekly-calendar dce--${CT.weekly}`, {
        pill: eventsLocation == "pills",
        stack: eventsLocation == "stack",
      })}
    >
      <div className={"weekly-calendar--heading-content"}>
        <CalendarHeader
          disabled={events.fetch}
          calendar={calendar}
          currentDate={props.selectedDate}
          onChangeDate={null}
          events={events.collection}
          locations={events.locations}
          onUpcomingNavigationChange={props.onUpcomingNavigationChange}
          showMonthSelector={false}
          selectedFilters={props.selectedFilters}
          onChangeFilter={props.onChangeFilter}
          onSearch={props.onKeywordSearch}
        />

        {search}
        <DateRangeSelector
          currentWeek={firstDayOfWeek(state.currentWeek)}
          onChangeWeek={onChangeWeek}
          allowChangingWeeks={allowChangingWeeks}
          showUpcomingEventsOnly={eventsNumberOption == "upcoming"}
        />
      </div>
      <div className={"weekly-calendar--container"}>{calendarDays}</div>
      {eventsListContent}
      {watermark}
      {modalEvent}
    </div>
  );
};

WeeklyView.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  onSelectedDateChange: PropTypes.func.isRequired,
  onUpcomingNavigationChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  initialized: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
  previewType: PropTypes.oneOf(Object.values(PT)),
  onChangeFilter: PropTypes.func.isRequired,
  onKeywordSearch: PropTypes.func,
  selectedFilters: PropTypes.object.isRequired,
};

export default WeeklyView;
