import React, { useEffect } from "react";
import PropTypes from "prop-types";
import EventBadges from "../EventBadges";
import WeekDayEventTime from "./WeekDayEventTime";
import PrivateEventBadge from "../PrivateEventBadge";
import { sortEventsWithAllDayFirst } from "../../../../helpers/calendar";

const WeekDayEvents = (props) => {
  const { events, day } = props;

  const onClickEvent = (event) => (e) => {
    e.preventDefault();

    props.onSelectedEvent(event);
    return false;
  };

  return (
    <div className="weekly-cal--day__event_container ">
      {events.sort(sortEventsWithAllDayFirst).map((event, edx) => {
        const inlineStyles = {};

        if (props.useEventAccent && event.accentColor) {
          if (props.isPills) {
            inlineStyles.backgroundColor = event.accentColor;
            // inlineStyles.color = event.fontColor;
          } else if (props.isStack) {
            inlineStyles.borderColor = event.accentColor;
          }
        }

        const isRecurring = event.recurrence_description && props.showRecurring;

        const badges = (
          <EventBadges
            showFeaturedBadge={props.showFeatured && event.is_featured}
            showRecurringBadge={props.showRecurring && isRecurring}
          />
        );

        const privateEventBadge =
          event.is_private * 1 == 1 && !props.embedded ? (
            <PrivateEventBadge />
          ) : null;

        return (
          <div
            key={"wde" + edx}
            className={"weekly-cal--day__event"}
            onClick={onClickEvent(event)}
            style={inlineStyles}
          >
            {privateEventBadge}
            {badges}

            <span className={"weekly-cal--day__event-desc"}>
              <span className={"time"}>
                <WeekDayEventTime event={event} day={day} />
              </span>{" "}
              {event.name}
            </span>
          </div>
        );
      })}
    </div>
  );
};
WeekDayEvents.propTypes = {
  events: PropTypes.array,
  day: PropTypes.object,
  onSelectedEvent: PropTypes.func.isRequired,
  useEventAccent: PropTypes.bool,
  isPills: PropTypes.bool,
  isStack: PropTypes.bool,
  showRecurring: PropTypes.bool,
  showFeatured: PropTypes.bool,
};

export default WeekDayEvents;
